import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Ragu`}</h1>
    <p>{`Adapted from Salt Fat Acid Heat for personal usage and for easy access to the recipe list`}</p>
    <h3>{`INGREDIENTS`}</h3>
    <p>{`Purchase`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Beef Chuck`}</strong>{`, 1 pound, `}<em parentName="p">{`coursely ground`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Pork Shoulder`}</strong>{`, 1 pound, `}<em parentName="p">{`coarsely ground`}</em></p>
      </li>
    </ul>
    <p>{`On hand`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Olive Oil`}</strong>{`, extra virgin, should be very fresh and peppery to taste`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Carrot`}</strong>{`, 1 large, `}<em parentName="p">{`minced`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Celery`}</strong>{`, 2 large stalks, `}<em parentName="p">{`minced`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Red Wine`}</strong>{`, 1 1/2 cups, `}<em parentName="p">{`dry`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Chicken Stock`}</strong>{`, 2 cups`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Bay Leaves`}</strong>{`, 2`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Lemon`}</strong>{`, 1 strip 1x3 inches, `}<em parentName="p">{`zested`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Orange`}</strong>{`, 1 strip 1x3 inches, `}<em parentName="p">{`zested`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Cinnamon Stick`}</strong>{`, 1/2 inch piece`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Tomato Paste`}</strong>{`, 5 tablespoons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Parmesan`}</strong>{`, 1, `}<em parentName="p">{`rind`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Salt`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Black Pepper`}</strong>{`, `}<em parentName="p">{`freshly ground`}</em></p>
      </li>
    </ul>
    <h3>{`DIRECTIONS`}</h3>
    <ol>
      <li parentName="ol">{`Make the soffritto`}</li>
      <li parentName="ol">{`...`}</li>
      <li parentName="ol"></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      